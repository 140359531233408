.dogCardHand {
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 1000rem;
    margin: 0 auto;
}

.frontCover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EFEFEF;
    height: 100vh;
    z-index: -1;
}

.frontCover svg {
    font-size: 10.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    background-color: transparent;
}

.startButton {
    color: #000;
    z-index: 3;
    display: flex;
    justify-content: center;
    margin-top: 50vh;
    height: 50rem;
    width: auto;

    font-size: 2.5rem;
    font-weight: 700;
    padding: 1rem 2rem;
    border-radius: 10px;
}

.title {
    z-index: 2;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    width: auto;

    margin-bottom: 5rem;

}
