/* LogIn.module.css */
.logInPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
    margin-bottom: 20px;
}
.container {
    margin-bottom: 1vh;
}

.logInForm {
    display: flex;
    flex-direction: column;
    align-items: center;

}

input, textarea {
    background-color: #FECC90;
}

a {
    color: #E7BCC8; /* Change to your desired color */
}


#usernameInputBox,
#passwordInputBox {
    margin-bottom: 3rem;
    height: 2rem;
    border-radius: 1rem;
    border: none;
    padding-left: 1rem;
    font-size: small;
    width: 100%;
}

#usernameInputBox input,
#passwordInputBox input {
    font-size: 2rem;
    width: 100%;
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 5px;
}

#rememberMeCheckBox {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 8rem;
    justify-content: space-between;
}

.submitButton {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.submitButton svg {
    height: 7vh;
    width: auto;
}

.submitButton:hover {
    color: #bdc8ff;
}

.signUpButton {
    height: 8vh;
    width: auto;
}
