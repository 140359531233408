.profilePage {
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
	background-color: #EFEFEF;
	position: relative;
}

.settingsButton {
	z-index: 2; /* Ensure settings button is on top */
	position: absolute;
	top: 20px;
	right: 0;
}


.background {
	position: absolute;
	display: flex;
	width: 25%;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: -1;
	max-width: 150%;
	max-height: 150%;
}


.infoBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh	;
	border-radius: 10px;
	padding: 2rem;
	position: relative;
	position: relative;
	margin-top: 12vh;
}

.statsBox {
	padding: 1%;
	border-radius: 25px;
	background-color: #E7BCC8;

}

.statTable {
	margin-left: 6%;
}


svg {
	width: 5%;
	height: auto;
}
h1 {
	color: #5D3819;
}
