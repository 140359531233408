/* Players Info */
.dogBattle {
    display: flex;
    height: 100vh;
    width: 100vw;
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

}

.playerInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.playerPhoto {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.opponentPhoto {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.opponentInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.opponentSide {
    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: column;
    right: 0;
    top: 0;
    margin-top: 20px;
    margin-right: 20px;
}



.userSide {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    left: 0;
    margin-bottom: 20px;
    margin-left: 20px;
}


.battleField {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f0f0f0;

}

.battleField__opponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    width: 20%;
}



.battleField__user {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
}

.battleField__battleState {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}