.deckManager {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  background-color: lightgray;
  margin: 50px;
  border-radius: 25px;
}


.currentHand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.stockCards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}