

.SignUpForm {
	display: flex;
	flex-direction: column;
	align-items: center;
}



#usernameInputBox,
#passwordInputBox {
	margin-bottom: 3rem;
	height: 2rem;
	border-radius: 1rem;
	border: none;
	padding-left: 1rem;
	font-size: small;
	width: 100%;
}

#usernameInputBox input,
#passwordInputBox input {
	font-size: 2rem;
	width: 100%;
	border: none;
	outline: none;
	padding: 10px;
	border-radius: 5px;
}


#rememberMeCheckBox {
	margin-bottom: 3rem;
	flex-direction: row;
	width: 8rem;
	justify-content: space-between;
}

.signUpButton {
	display:flex;
	justify-content: center;
	margin: auto;
	font-size: 2rem;
	margin-top: 1rem;

}
.signUp {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
}

#signUpButton:hover {
	color: #bdc8ff;
}
