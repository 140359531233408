.gameMap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f0f0f0;
}

.profileButton {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 10px;

    cursor: pointer;
    z-index: 3;
}

.profileButton:hover {
    background-color: #e0e0e0;

}

.map {
    height: 100vh;
    width: 100vw;
}


.cardHand {
    align-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 2rem;
    margin-right: 3rem;
    padding: 10px;
    justify-content: center;
}
